<template>
  <div class="location-items">
    <div class="row main-row">
      <div class="col-md-4 small-col">
        <div class="item location-item">
          <div class="title">
            <div class="icon">
              <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 0.875C8.26596 0.878225 5.6448 1.96575 3.71153 3.89902C1.77826 5.83229 0.690736 8.45345 0.687511 11.1875C0.684236 13.4218 1.41406 15.5954 2.76501 17.375C2.76501 17.375 3.04626 17.7453 3.0922 17.7988L11 27.125L18.9116 17.7941C18.9528 17.7444 19.235 17.375 19.235 17.375L19.2359 17.3722C20.5862 15.5934 21.3157 13.4207 21.3125 11.1875C21.3093 8.45345 20.2218 5.83229 18.2885 3.89902C16.3552 1.96575 13.7341 0.878225 11 0.875ZM11 14.9375C10.2583 14.9375 9.53331 14.7176 8.91662 14.3055C8.29994 13.8935 7.81929 13.3078 7.53546 12.6226C7.25163 11.9373 7.17737 11.1833 7.32207 10.4559C7.46676 9.72848 7.82391 9.0603 8.34836 8.53585C8.87281 8.0114 9.54099 7.65425 10.2684 7.50955C10.9959 7.36486 11.7499 7.43912 12.4351 7.72295C13.1203 8.00678 13.706 8.48743 14.118 9.10411C14.5301 9.7208 14.75 10.4458 14.75 11.1875C14.7488 12.1817 14.3533 13.1348 13.6503 13.8378C12.9473 14.5408 11.9942 14.9363 11 14.9375Z"
                  fill="#0349A8"/>
              </svg>
            </div>
            <h2>{{ this.$i18n.locale == 'ar' ? 'مواقعنا' : 'Our sites' }}</h2>
          </div>
          <a href="https://maps.app.goo.gl/SEnhMh3pLwjSYHin8" target="_blank" rel="noopener noreferrer">
            <div class="d-flex align-items-center">
              <strong>
                {{ address_central_1 }}
              </strong>
              <span>{{ address_central_2 }}</span>
            </div>
          </a>
          <a href="https://maps.app.goo.gl/ctw7UHU9n23SGkW5A" target="_blank" rel="noopener noreferrer">
            <div class="d-flex align-items-center">
              <strong>
                {{ address_eastern_1 }}
              </strong>
              <span>{{ address_eastern_2 }}</span>
            </div>
          </a>
          <a href="https://maps.app.goo.gl/3otb5vKhLBu4yUJ58" target="_blank" rel="noopener noreferrer">
            <div class="d-flex align-items-center">
              <strong>
                {{ address_southern_1 }}
              </strong>
              <span>{{ address_southern_2 }}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4 small-col">
        <div class="item">
          <div class="title">
            <div class="icon">
              <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.36335 0.891395L5.43871 0.265681C6.12364 0.0595019 6.8602 0.109527 7.51097 0.406424C8.16173 0.703322 8.68228 1.22682 8.97549 1.87925L10.4305 5.11497C10.683 5.67631 10.7534 6.30265 10.6318 6.90605C10.5101 7.50944 10.2026 8.05959 9.75228 8.47925L7.53764 10.5428C7.24085 10.8246 7.46585 11.9228 8.55014 13.8021C9.63549 15.6825 10.4744 16.426 10.8612 16.3103L13.7626 15.4232C14.3508 15.2433 14.9804 15.2519 15.5634 15.4479C16.1463 15.6439 16.6533 16.0174 17.0134 16.516L19.0812 19.3821C19.4989 19.9609 19.6923 20.6715 19.6255 21.3821C19.5587 22.0927 19.2362 22.7549 18.718 23.2457L17.1194 24.7596C16.6034 25.2483 15.9669 25.5914 15.275 25.7538C14.5831 25.9163 13.8605 25.8923 13.1809 25.6843C9.83157 24.6589 6.72978 21.616 3.83371 16.5985C0.931207 11.5735 -0.164864 7.33497 0.599065 3.87211C0.753141 3.17435 1.09052 2.5303 1.57642 2.00635C2.06232 1.48241 2.67916 1.09753 3.36335 0.891395Z"
                  fill="#0349A8"/>
              </svg>
            </div>
            <h2>{{ this.$i18n.locale == 'ar' ? 'أرقام التواصل' : 'Contact Numbers' }}</h2>
          </div>
          <a :href="'tel:' + phone_number_1">
            <div><span> {{ phone_number_1 }}</span></div>
          </a>
          <a :href="'tel:' + phone_number_2">
            <div><span>{{ phone_number_2 }}</span></div>
          </a>
        </div>
      </div>
      <div class="col-md-4 small-col">
        <div class="item">
          <div class="title">
            <div class="icon">
              <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.75 0H4.25C3.25544 0 2.30161 0.395088 1.59835 1.09835C0.895088 1.80161 0.5 2.75544 0.5 3.75V16.25C0.5 17.2446 0.895088 18.1984 1.59835 18.9017C2.30161 19.6049 3.25544 20 4.25 20H21.75C22.7446 20 23.6984 19.6049 24.4017 18.9017C25.1049 18.1984 25.5 17.2446 25.5 16.25V3.75C25.5 2.75544 25.1049 1.80161 24.4017 1.09835C23.6984 0.395088 22.7446 0 21.75 0ZM21.75 2.5L13.625 8.0875C13.435 8.19721 13.2194 8.25497 13 8.25497C12.7806 8.25497 12.565 8.19721 12.375 8.0875L4.25 2.5H21.75Z"
                  fill="#0349A8"/>
              </svg>
            </div>
            <h2>{{ $t('Email') }}</h2>
          </div>
          <a :href="`mailto:${email_address}`">
            <div><strong>{{ email_address }}</strong></div>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    phone_number_1: {
      type: String
    },
    phone_number_2: {
      type: String
    },
    email_address: {
      type: String
    },
    address_central_1: {
      type: String
    },
    address_central_2: {
      type: String
    },
    address_eastern_1: {
      type: String
    },
    address_eastern_2: {
      type: String
    },
    address_southern_1: {
      type: String
    },
    address_southern_2: {
      type: String
    }
  }
}
</script>
